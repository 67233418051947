
<section class="container" >
  <div class="price-table">
      <div class="row">
          <div class="col-sm-12 col-md-12" >
              <div class="single-price price-three" >
                  <div class="table-heading">
                      <p class="plan-name" style="color: black;"  ><strong>Plan Unico</strong></p>
                      <p class="plan-price"  style="color: black;" ><span  class="dollar-sign">$</span><span   *ngIf="total>0"  class="price" >{{total}}</span></p>
                  </div>
                  <div class="form-group">
                    <label for="">Cantidad de Votaciones</label>
                    <input type="number" [(ngModel)]="cantidadVotaciones" id="cantidadVotacion" name="cantidadVotacion" placeholder="cantidad de votaciones" class="form-control" required="required">
                </div>
                  <div class="form-group">
                    <label for="">Cantidad de Personas</label>
                    <input type="number" [(ngModel)]="cantidad" id="cantidad" name="cantidad" placeholder="cantidad de votantes" class="form-control" required="required">
                </div>
                <button  class="btn btn-buynow" style="background-color: red;" (click)="calcularPrecio()"><strong>Calcular</strong></button>
                <br>
                  <ul>
                      <li>Sudominio Personalizado<span><i class="fa fa-check"></i></span></li>
                      <li>Reporte de no votante cada 3 horas <span><i class="fa fa-check"></i></span></li>
                      <li>Validación de Padron<span><i class="fa fa-check"></i></span> </li>
                      <li>Soporte al votante via chat<span><i class="fa fa-check"></i></span></li>
                      <li>Verificacion en dos pasos<span><i class="fa fa-check"></i></span></li>
                      <li>Reporte de escrutinio<span><i class="fa fa-check"></i></span></li>
                      <li>Reporte de resultados<span><i class="fa fa-check"></i></span></li>
                      <li>Estadisticas de la votación minuto a minuto<span><i class="fa fa-check"></i></span></li>
                      <li>Validador de voto<span><i class="fa fa-check"></i></span></li>
                      <li>Videtutorial personalizado<span><i class="fa fa-check"></i></span></li>
                      <li>Recordatorio a votantes para que voten por correo<span><i class="fa fa-check"></i></span></li>
                      <li>Acta de apertura al momento de realizarla<span><i class="fa fa-check"></i></span></li>
                  </ul>


              </div>
          </div>



      </div>
  </div><!--/#price-table-->
</section>

