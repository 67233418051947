<br>
<br>
<div style="background-color:black;color: white">
  <div class="container text-center">
    <div class="row row-cols-2">
      <div class="col">
        <h2 style="color: white;">Contactanos</h2>
        <address>
        E-mail: <a href="mailto:admin&#64;fairvoting.cl" style="color: white;">admin&#64;fairvoting.cl</a> <br>
        Celular : +56 9 87827775
        </address>
      </div>
      <div class="col"><h2 style="color: white;">Dirección</h2>
        <address>
          Avenida Providencia, <br>
          N*1208, <br>
          oficina 207, <br>
          Providencia <br>
        </address></div>

    </div>
  </div>

    <p style="text-align: center;">&copy; Copyright © LFCBSERVICES 2023.</p>


  </div>



