import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { SomosComponent } from './pages/somos/somos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { NavComponent } from './menus/nav/nav.component';
import { FooterComponent } from './menus/footer/footer.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';


import { PrecioComponent } from './pages/precio/precio.component';
@NgModule({
  declarations: [
    AppComponent,
    ServiciosComponent,
    SomosComponent,
    ContactoComponent,
    NavComponent,
    FooterComponent,
    InicioComponent,
    PrecioComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SweetAlert2Module,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
