<div class="container-xl">
  <div class="card text-center">
    <div class="card-header" style="color: red;">
      <strong>CONTÁCTANOS</strong>
    </div>
    <div class="card-body">
      <h5 class="card-title" ><strong>Nos comunicamos contigo de inmediato!</strong></h5>
      <br>
      <form [formGroup]="form" id="main-contact-form">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Nombre</label>
          <input type="text" formControlName="nombre" name="nombre" required="required" class="form-control" >

        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Celular</label>
          <input type="text" formControlName="celular" name="celular" required="required" class="form-control" id="exampleInputPassword1">
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Correo</label>
          <input type="email" formControlName="correo"  name="correo" required="required" class="form-control" >
          <span *ngIf="this.form.get('correo')?.errors?.['email']">

            Debe tener formato de correo ejemplo correo&#64;gmail.com

        </span>
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Cuentanos sobre tu votación</label>
          <textarea formControlName="problema"  name="problema" class="form-control" required="required"> </textarea>
        </div>

        <button type="submit"  name="submit" (click)="EnviarContacto()" class="btn btn-primary" style="background-color: red;">Enviar</button>
      </form>
    </div>
    <div class="card-footer text-muted">
      Fairvoting votación electronica a precio justo.
    </div>
  </div>

</div>
