<div style="background-color:black">
<br>
<br>
</div>
<br>
<a class="navbar-brand" href="index.html">
  <h1><img src="../../../assets/VPTING_-removebg-preview.png" alt="logo"></h1>
</a>
<ul class="nav justify-content-center">


  <li class="nav-item">
    <a class="nav-link active" aria-current="page" href="#" routerLink="/" style="color: red;"> <strong>INICIO</strong></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#" routerLink="/precio" style="color: red;"><strong>PRECIOS</strong></a>
  </li>
  <li class="nav-item">
    <a class="nav-link " routerLink="/contacto" style="color: red;"> <strong>CONTACTO</strong></a>
  </li>
</ul>
<br>
<br>
