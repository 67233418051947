<section id="page-breadcrumb">
  <div class="vertical-center sun">
       <div class="container">
          <div class="row">
              <div class="action">
                  <div class="col-sm-12">
                      <h1 class="title">Sobre nosotros</h1>
                      <p>Por qué a nuestras clientas les encanta trabajar con nosotros.</p>
                  </div>
               </div>
          </div>
      </div>
  </div>
</section>
<!--/#page-breadcrumb-->

<section id="about-company" class="padding-top wow fadeInUp" data-wow-duration="400ms" data-wow-delay="400ms">
  <div class="container">
      <div class="row">
          <div class="col-sm-12 text-center">
              <img src="../../../assets/istockphoto-544001460-612x612-removebg-preview.png" class="margin-bottom" alt="">
              <h1 class="margin-bottom">Sobre nuestra empresa</h1>
              <p>Como empresa, nos dedicamos a prestar un servicio mejor cada día. Siempre escuchamos las recomendaciones de nuestros clientes para seguir mejorando, pero sobre todo, nos centramos en garantizar que cada cliente esté satisfecho con el servicio prestado para que pueda ejercer su derecho a la democracia, porque cada voto cuenta.</p>
          </div>
      </div>
  </div>
</section>
<!--/#about-company-->

<section id="services">
  <div class="container">
      <div class="row">
          <div class="col-sm-4 text-center padding wow fadeIn" data-wow-duration="1000ms" data-wow-delay="300ms">
              <div class="single-service">
                  <div class="wow scaleIn" data-wow-duration="500ms" data-wow-delay="300ms">
                      <img src="../../../assets/Senales-auditoria-de-calidad-1024x538-removebg-preview.png" alt="">
                  </div>
                  <h2>Apasionados</h2>
                  <p>Nos apasionamos por el trabajo, por la atención al cliente y por el buen servicio.</p>
              </div>
          </div>
          <div class="col-sm-4 text-center padding wow fadeIn" data-wow-duration="1000ms" data-wow-delay="600ms">
              <div class="single-service">
                  <div class="wow scaleIn" data-wow-duration="500ms" data-wow-delay="600ms">
                      <img src="../../../assets/2728606-removebg-preview.png" alt="">
                  </div>
                  <h2>Transparentes</h2>
                  <p>No guardamos ni compartimos ninguna información personal.</p>
              </div>
          </div>
          <div class="col-sm-4 text-center padding wow fadeIn" data-wow-duration="1000ms" data-wow-delay="900ms">
              <div class="single-service">
                  <div class="wow scaleIn" data-wow-duration="500ms" data-wow-delay="900ms">
                      <img src="../../../assets/calidad_en_la_empresa-removebg-preview.png" alt="">
                  </div>
                  <h2>Calidad</h2>
                  <p>No nos basta decir que tienes el mejor servicio disponible, nos aseguramos de garantizar que así sea.</p>
              </div>
          </div>
      </div>
  </div>
</section>
<!--/#services-->


<!--/#action-->




