<div class="container text-center">
  <div class="row row-cols-1">
    <div class="col">


      <h1 style="color: red;"><strong>Fairvoting precio justo</strong></h1>

      <h3>Plataforma de votación online</h3>


      <a  routerLink="/precio" style="margin: 2px;" class="btn btn-common" style="color: red;"> <strong>Ver precios</strong></a>

</div>

    <div class="col">
      <h1 style="color: black;"> <strong>Autorizados por la dt</strong></h1>
      <img src="../../../assets/dt-removebg-preview.png" width="160px" class="slider-birds2" alt="slider image">




      <a  href="https://www.dt.gob.cl/legislacion/1624/articles-124674_recurso_pdf.pdf" style="margin: 2px;" class="btn btn-common" style="color: red;"> <strong>Autorización DT</strong></a>
      <h1 style="color: black;"> <strong>Asociados con DF ABOGADOS </strong></h1>
<a href="https://dfabogados.cl/" target="_blank" >
      <img src="../../../assets/sdsdfd.png" width="160px" class="slider-birds2" alt="slider image">
    </a>

      <br>
    </div>

  </div>
</div>
<br>
<div class="container-fluid" >

  <h1 style="text-align: center;color: red;" ><strong>Servicios</strong></h1>
      <ul class="list-group" style="text-align: center;border: 3px solid black">
        <li class="list-group-item"><strong>Votación comité paritario.</strong></li>
        <li class="list-group-item"><strong>Votación última oferta.</strong></li>
        <li class="list-group-item"><strong>Votación elección de directorio.</strong></li>
        <li class="list-group-item"><strong>Votaciones ponderadas.</strong></li>
        <li class="list-group-item"><strong>Votación desafiliación CCAF y elección CCAF.</strong></li>
        <li class="list-group-item"><strong>Votación reforma de estatutos.</strong></li>
        <li class="list-group-item"><strong>Votación consultas.</strong></li>
        <li class="list-group-item"><strong>Encuestas y votaciones adaptadas a tu necesidades.</strong></li>
      </ul>

</div>

<div class="container-fluid">
  <h1 style="text-align: center;color: red;"><strong>Beneficios de trabajar con nosotros</strong></h1>
  <div class="container text-center">
    <div class="row">
      <div class="col">

          <img src="../../../assets/png-clipart-cloud-drawing-cloud-white-cloud-thumbnail-removebg-preview.png"  width="200px" alt="">

      <h2> <strong>Subdominio que quieras!</strong></h2>
      <p>Tu puedes elegir el subdominio, si no sabes cual nosotros te lo sugerimos.</p>
      </div>
      <div class="col">

          <img src="../../../assets/RESPONSIVA-removebg-preview.png"  width="200px" alt="">

      <h2> <strong>Sistema responsivo</strong></h2>
      <p>El sistema se adapta al dispositivo a cualquier dispositivo sea celular,notebook o tablet.</p>
      </div>
      <div class="col">
        <img src="../../../assets/istockphoto-1196467762-612x612-removebg-preview.png"  width="200px" class="img-responsive" alt="">

          <h2> <strong>Mejores Precios</strong></h2>
          <P>Tenemos los mejores precios del mercado y si encuentras uno mejor te lo rebajamos.</P>

      </div>
    </div>
    <div class="row">
      <div class="col">
        <img src="../../../assets/5695957.png" width="200px" class="img-responsive" alt="">
        <h2><strong>Facil de usar</strong> </h2>
        <p>Facilidad y sencilles mejor no vas a encontrar.</p>
      </div>

      <div class="col">
        <img src="../../../assets/imagen-calidad-humana-removebg-preview.png"  alt="">
        <h2><strong>Calidad Humana</strong>  </h2>
                  <P>Siempre estamos atentos a las solicitudes y a los cambios de cada cliente, queremos ayudarte a que el proceso sea exitoso.</P>
      </div>
      <div class="col">
        <img src="../../../assets/133-1339454_virtual-assistant-soporte-tecnico-removebg-preview.png" alt="">
        <h2> <strong>Soporte activo </strong> </h2>
                      <p>Calidad humana para atenderte en todo lo que necesitas para realizar tu proceso de votación.</p>
      </div>
    </div>
  </div>

</div>

<hr>
<div class="container-xl">
  <div class="card text-center">
    <div class="card-header" style="color: red;">
      <strong>CONTÁCTANOS</strong>
    </div>
    <div class="card-body">
      <h5 class="card-title" ><strong>Nos comunicamos contigo de inmediato!</strong></h5>
      <br>
      <form [formGroup]="form" id="main-contact-form">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Nombre</label>
          <input type="text" formControlName="nombre" name="nombre" required="required" class="form-control" >

        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Celular</label>
          <input type="text" formControlName="celular" name="celular" required="required" class="form-control" id="exampleInputPassword1">
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Correo</label>
          <input type="email" formControlName="correo"  name="correo" required="required" class="form-control" >
          <span *ngIf="this.form.get('correo')?.errors?.['email']">

            Debe tener formato de correo ejemplo correo&#64;gmail.com

        </span>
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Cuentanos sobre tu votación</label>
          <textarea formControlName="problema"  name="problema" class="form-control" required="required"> </textarea>
        </div>

        <button type="submit"  name="submit" (click)="EnviarContacto()" class="btn btn-primary" style="background-color: red;">Enviar</button>
      </form>
    </div>
    <div class="card-footer text-muted">
      Fairvoting votación electronica a precio justo.
    </div>
  </div>

</div>

<div class="container-fluid">
  <h1 style="text-align: center;color: red;"><strong>Algunos clientes que han confiado en nosotros</strong></h1>

  <h1 style="text-align: center;color: black;"><strong>Testimonios</strong></h1>
  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"  style="text-align: center;">

        <div class="card">
          <div class="card-body">
            <h3><strong>Vanessa Herrera Pinto</strong></h3>

            <p>Acabamos de tener un proceso de votación.donde no hubo ni una sola dificultad y donde en todo momento fuimos retroalimentados del proceso.
               Antes durante y después tuvimos el soporte necesario para que todo fuera exitoso.
                Lo recomiendo totalmente.</p>
  <label>Consejo de defensa del estado</label>
          </div>
        </div>

      </div>
      <div class="carousel-item" style="text-align: center;">
        <div class="card">
          <div class="card-body">
        <h3><strong>Comunicaciones SINDOC UACH</strong></h3>
        <p>Como Sindicato de Docentes de la Universidad Austral de Chile.
          Hemos trabajado en dos oportunidades con la empresa Fairvoting y podemos garantizar que son responsables y serios con su trabajo, por lo cual la recomendamos.</p>
      <label>Universidad Austral de chile</label>
    </div>
  </div>
        </div>
      <div class="carousel-item" style="text-align: center;">
        <div class="card">
          <div class="card-body">
        <h3><strong>Felipe Montecinos</strong></h3>
        <p>Se valora la comunicación y gestión en lo que respecta al tramite del servicio.
          Las votaciones por esta plataforma funcionaron correctamente y puedes estas atento al computo constantemente.</p>
          <label>Sindicato la polar</label>
        </div>
      </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button"  data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" style="color: red;" aria-hidden="true"></span>
      <span class="visually-hidden" >Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" style="color: red;" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <h1 style="text-align: center;color: black;"><strong>Algunos clientes</strong></h1>
  <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"  style="text-align: center;">
  <div class="container text-center">
    <div class="row row-cols-3">
      <div class="col">
        <img src="../../../assets/cde.jpg" width="140px"  alt="">

      </div>
      <div class="col">

        <img src="../../../assets/globaldata.png" width="140px"  alt="">
      </div>


      <div class="col">

        <img src="../../../assets/abenis.png" width="140px"  alt="">
      </div>
</div>
    </div>

</div>
<div class="carousel-item "  style="text-align: center;">
  <div class="container text-center">
    <div class="row row-cols-3">
      <div class="col">
        <img src="../../../assets/us.png" width="140px"  alt="">

      </div>
      <div class="col">

        <img src="../../../assets/dyc.png" width="140px"  alt="">
      </div>


      <div class="col">

        <img src="../../../assets/obiedo.png" width="140px"  alt="">
      </div>
</div>
    </div>

</div>
  </div>

  <button class="carousel-control-prev" type="button"  data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" style="color: red;" aria-hidden="true"></span>
    <span class="visually-hidden" >Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
    <span class="carousel-control-next-icon" style="color: red;" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</div>
