import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fairvotingService } from 'src/app/service/fairvoting';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  form!: FormGroup;
  constructor(
    private formBuilder : FormBuilder,
    private fairvotingService: fairvotingService
  ) { }

  ngOnInit(): void {

    this.rescataVotacionForm();
  }
  rescataVotacionForm() {
    this.form = this.formBuilder.group({
      nombre : ['',[Validators.required]],
      celular : ['',[Validators.required]],
      correo: ['', [Validators.required,Validators.email]],
      problema: ['',[Validators.required]],



    });
  }

  EnviarContacto(){

    if (this.form.valid){

    this.fairvotingService.agregarVotacion(this.form.value).subscribe(
      res =>{
        console.log(res);
        //this.router.navigate(['/'])
        Swal.fire('Atencion', 'Contacto enviado!', 'success');

      },
      err => console.log(err)
    );

  }
  else{
    Swal.fire('Atencion', 'Todos los campos son necesarios!', 'info');
  }

  }

}
