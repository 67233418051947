import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  

  constructor(
   
    private http: HttpClient
   
  ) { }
  ngOnInit(): void {
    
  }
   sendEmail(){
    return this.http.post('https://api:e35160eb2d9de08fe3e7a980c9f1a7c1-2de3d545-f5581221@api.mailgun.net/v3/lf.lfcbservices.cl/messages', {
                from: "Notificador Confirmación de Voto <admin@lf.lfcbservices.cl>",
                to: 'laroquein@gmail.com',
                subject: "Subject text",
                text: "Body text"
            }).subscribe(res => {

              console.log(res);
            })
        }


 

}
