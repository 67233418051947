import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.prod";

@Injectable({
    providedIn: 'root'
  })
export class fairvotingService{

    constructor( private http: HttpClient) { }

    agregarVotacion(body:any){
        return  this.http.post( `${environment.api}contacto`,body,{headers:{skip:"true"}});
       }

}