import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { SomosComponent } from './pages/somos/somos.component';
import { PrecioComponent } from './pages/precio/precio.component';

const routes: Routes = [
  {path: '', component: InicioComponent},
  {path: 'servicio', component: ServiciosComponent},
  {path: 'conocenos', component: SomosComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'precio', component: PrecioComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
